nav {
    position: fixed; top: 0; left: 0; right: 0; z-index: 5;
    height: 96px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
    background-color: white;
}
.logo {
    height: 56px;
    float: left;
    margin-top: 20px;
}
.hamburg,
.closer {
    float: right;
    margin: 34px 0 0 0;
}
.closer {
    font-size: 50px;
    font-weight: 300;
    margin-top: 3px;
    cursor: pointer;
}
.inner {
    width: 1200px;
    margin: 0 auto;
}
nav .inner,
.global.inner,
.articlespage .inner,
.contactpage .inner,
footer .inner,
#bignav .inner {
    width: 1160px;
}
h1.herotext {
    line-height: 64px
}

.inner.hero {
    position: relative;
}
.inner.hero img {
    width: 709px; height: 709px;
    position: absolute; top: 0; right: -100px;
}
.hero .textblock {
    width: 620px;
    padding: 150px 30px;
}
.hero .textblock p {
    margin-bottom: 50px;
}
.pagehero,
.lead {
    width: auto;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 80px;
}
.pagehero .inner {
    margin: 0 auto;
    width: 1160px;
    height: 480px;
}
.pagehero .inner > div:first-child {
    padding-top: 100px;
}
.pagehero p {
    width: 560px;
}
.shadowbox {
    margin: 0 auto;
    width: 1200px;
    height: 188px;
    padding: 60px 64px 64px 64px;
    box-shadow: 0 12px 48px 0 rgba(0, 0, 0, 0.08);
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    border-radius: 4px;
    text-align: center;
    position: relative; z-index: 2;
    background-color: white;
}
.global {
    padding: 120px 0 100px 0;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
}
.global div {
    text-align: center;
    flex: 0 1 auto;
    width: 360px;
}
.global div h4 {
    margin: 15px;
}
.articles h1,
.inner.services {
    width: 1160px;
    margin: 0 auto;
}
.articles h1 {
    margin-bottom: 10px;
}
.scrollcontainer {
    padding: 15px 0 40px 0;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}
.scrollcontainer .box {
    display: inline-block;
    vertical-align: top;
    width: 1160px;
    min-height: 372px;
    padding: 40px 40px 40px 50px;
    border-radius: 4px;
    box-shadow: 0 12px 32px 0 rgba(34, 31, 32, 0.12);
}
.scrollcontainer .box:not(:first-child) {
    margin-left: 40px;
}
.scrollcontainer .box:first-child {
    margin-left: calc((100vw - 1160px) / 2);
}
.scrollcontainer .box:last-child {
    margin-right: calc((100vw - 1160px) / 2);
}
.scrollcontainer .box .text {
    width: 520px;
    white-space: normal
}
.scrollcontainer .box .img {
    width: 520px; height: 290px;
    background-color: transparent !important;
    opacity: .3;
}
.scrollcontainer .box .img > img {
    max-width: 100%;
    max-height: 100%;
    float: right;
}
.inner.services {
    margin-top: 130px
}
.inner.services h1,
.inner.services h4,
.inner.services p {
    margin-top: 0
}
.inner.services p {
    margin-bottom: 40px;
}
.inner.services h4 {
    position: relative;
    padding-left: 40px;
    font-size: 16px;
}
.inner.services h4 img {
    position: absolute; left: 0;
    width: 24px; height: 24px;
}
.services > div > div {
    width: 50%;
}
.services div.flex {
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
}
.services div.flex div.flex > div {
    width: 260px;
}
.services img.big {
    width: 560px;
}
.certifications {
    margin-top: 100px
}

.certifications {
    .items {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.icon-section{
    background-color:#007AE5;
    color:white;
    padding: 30px;
    text-align:center;
    font-size: 16px;
}

.icon-section img {
    width: 96px;
}

.technology-item{    
    margin: 15px;
    display: inline-block;
}


.case-studies {
    margin-top: 50px;
    color: white;
    background-color: #007AE5;
    padding: 60px 0 0 0;
    text-align: center;
}

.case-studies.inner {
    width: auto;
}

.case-studies {
    .case-studies-container {
        padding-top: 2em;
        padding-bottom: 2em;

        display: flex;
        justify-content: space-between;
    }
    
    .case-study-item {  
        margin: 1em;
        max-width: 350px; 

        img {
            max-width: 320px;
        }
    }
}

.btn-white {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    display: inline-block;
    line-height: 48px;
    width: 240px;
    height: 48px;
    box-shadow: 0 4px 12px 0 rgba(255, 255, 255, 0.48);
    color: #007ae5;
    background-color: white;
    border-radius: 5px;
    border: 0;
    margin-right: 0px !important;
    cursor: pointer;
}

.btn-white:active {
    background-color: white !important;
}

.btn-white:disabled,
.btn-white:disabled:hover {
    cursor: not-allowed;
    box-shadow: unset;
    background-color: rgba(255,255,255, 0.3) !important;
}


.technologies {
    margin-top: 200px;
    color: white;
    background-color: #007AE5;
    height: 440px;
    padding: 81px 0 0 0;
    text-align: center;
}
.technologies.inner {
    width: auto;
}

.technologies h2 {
    margin-top: 0
}
.technologies img {
    width: 128px; 
    margin: 30px 10px 10px 10px;
}

.clients {
    margin-top: 200px;
    color: white;
    background-color: #007AE5;
    padding: 81px 0 81px 0;
    text-align: center;
}
.clients.inner {
    width: auto;
}
.clients > div:first-of-type {
    margin: 0 auto;
}
.clients h2 {
    margin-top: 0
}

.clients {
    .logo-items {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.clients img {
    max-width: 256px; 
    max-height: 100px;
    margin: 20px 40px;
}

.d-none{
    display:none;
}

.container {
    max-width: 1160px;
}

.projects {
    padding: 120px; 
    width: 1200px;
    margin: 0 auto;
}
.projects .flex > div:first-child {
    width: 60%;
}
.projects .flex > div:last-child {
    width: 40%;
}
.projects * {
    margin: 0 0 10px 0;
}
.projects button {
    float: right;
    margin: 10px 0 0 0;
    width: 300px;
}
.projects hr { 
    border: none;
    height: 1px;
    /* Set the hr color */
    color: #333; /* old IE */
    background-color: #333; /* Modern Browsers */
    margin-bottom: 60px;
}

hr.separator{
    margin: 1em 0em 1em 0em;    
    border : 0;
    height: 2px; 
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(34, 31, 32, 0.12), rgba(0, 0, 0, 0));
}

#bignav.hidden {
    display: block;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transform: translate3d(100px, 0, 0)
}
#bignav {
    position: fixed; z-index: 10;
    top: 0; right: 0; bottom: 0; left: 0;
    background: #007AE5;
    color: white;
}
#bignav .navitems {
    padding-top: 140px;
}
#bignav .navitems div {
    text-align: left;
    flex: 0 1 auto;
    width: 300px;
}
#bignav .navitems h1,
#bignav .navitems p {
    margin-top: 0
}
#bignav .navitems h1:after {
    content: " ";
    font-size: 4px;
    display: block;
    margin-top: 8px;
    width: 48px; height: 4px;
    border-radius: 10px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0);
}
#bignav .navitems h1:hover:after {
    background-color: rgba(255, 255, 255, 1);
}
#bignav .info {
    width: 900px;
    margin: 0 auto;
    padding-top: 180px;
    font-weight: 300;
}

#bignav .info {
    .phone-number{
        color:white;
    }
}

.footer-container{
    .phone-number{
        color: white;
    }
}

.pageheropic {
    width: 560px;
    text-align: center;
    // height: 420px; 
    // line-height: 420px;
    // box-shadow: inset 0 0 0 2px #007AE5;
    margin-top: 30px;
}
.pageheropic > img {
    max-width: 100%;
}
.collection {
    width: 800px;
    margin: 0 auto;
}
.collection > div {
    float: left;
    width: 360px;
    margin: 0 20px;
}
.collection .block {
    width: 360px;
    height: 360px;
}
.collection .block > img {
    max-width: 100%;
    max-height: 100%;
}
.block.black {
    background: black;
    box-shadow: inset 0 0 0 2px black;
}
.block.blue {
    background: #66B7FF;
    box-shadow: inset 0 0 0 2px #66B7FF;
}
.block.green {
    background: #57D9CA;
    box-shadow: inset 0 0 0 2px #57D9CA;
}
.block.pink {
    background: #E55CA6;
    box-shadow: inset 0 0 0 2px #E55CA6;
}
.block.yellow {
    background: #F2C161;
    box-shadow: inset 0 0 0 2px #F2C161;
}
.collection .text {
    padding: 20px;
}

.articlehero {
    background-color: black;
    color: white;
}
.articlehero .inner {
    width: 760px;
    margin: 0 auto;
    padding: 150px 200px 150px 0;
}
.articlehero .small {
    font-weight: 600
}
.articlehero .sep {
  width: 24px;
  height: 2px;
  border-radius: 2px;
  background-color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.articlehero h1 {
    margin-top: 20px
}
article .lead .inner {
    width: 960px;
    padding: 64px 0 56px 0;
}
article .lead .inner > div:first-child {
    width: 660px;
}
article .lead .inner > div:last-child {
    width: 260px;
}
article p, article li {
    color: #7A7A7A;
}
article .props .small {
    margin-top: 10px;
    margin-bottom: 5px;
}
article .props .gray {
    margin-bottom: 25px;
}
article .arttext {
    width: 760px;
    margin: 0 auto;
}
article .arttext p {
    margin-bottom: 30px
}
article .arttext h2 {
    margin-bottom: 0
}
article div.pic {
    margin: 60px 0;
    height: 420px;
    background-color: #F2E2C2
}
article img.vr {
    margin: 60px 0;
    width: 100%;
}

.contactpage h2 {
    margin-top: 0;
}
.socialmedia,
.socialmedia:visited {
    width: 40px; height: 40px;
    padding-top: 7px;
    text-align: center;
    border-radius: 50%;
    margin-bottom: 20px;
    margin-right: 20px;
    display: inline-block;
    color: white!important;
}
.socialmedia.fb {
    background-color: #3b5998!important
}
.socialmedia.tw {
    background-color: #00aced!important; 
}
.socialmedia.tm {
    background-color: #32506d!important
}
.socialmedia.yt {
    background-color: #a82400!important
}
form .row {
    padding-bottom: 25px;
}
form .row label {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.2px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 6px;
}
form .row input[type="text"],
form .row input[type="email"],
select,
textarea {
    border-radius: 4px;
    border: 1px solid #005299;
    height: 40px;
    padding: 0 15px;
    width: 100%;
    background-color: white;
}
select {
    padding: 0 10px;
}
textarea {
    height: 150px;
    padding: 5px 15px;
}
.video {
    text-align: center;
    padding-bottom: 40px;
}
.video > div {
    width: 100%;
    padding-bottom: 56.5%;
    position: relative;
}
.video > div > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

footer {
    position: absolute;
    bottom:0;
    left:0;
    width: 100%;
    z-index: 1;
    color: white;
    background-color: black;
    height: 112px;
}
footer .inner {
    padding-top: 28px;
    white-space: nowrap;
    font-weight: 300;
}

footer .inner .footer-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

footer .inner  .footer-container .social-media-container {
    text-align: right;
    padding-top: 15px;
}

.onlymob {
    display: none
}

.map-container {
    margin-top: 1em;
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   flex-direction: row;
}

.map-container > div {    
    width: 100%;
    padding: 1em;
    display: inline-block;  
    height: 100%;
}

.map-container > div > iframe {
    width: 100%;
    border: none;
}

.shadowy {
    display: inline-block;
    vertical-align: top;
    padding: 20px 20px 20px 50px;
    border-radius: 4px;
    box-shadow: 0 12px 32px 0 rgba(34, 31, 32, 0.12);
}

.shadow {
    margin: 0 auto;
      padding: 0.25em 0.25em 2.25em 0.25em;

      &.bottom{
        box-shadow: 0px 15px 32px -22px rgba(34, 31, 32, 0.5)
      }
}

.container{
    max-width: 1160px;
    margin: 0 auto;
}

hr.separator {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
}

.inner.marketing {
    margin-top: 5.5em;
    width: 1160px;
}

.inner.marketing > div > div > div {
    width: 50%;
}

.inner.marketing > div > div > div > img {
    width: 100%;
}

.inner.marketing .text-container{
    padding:2.25em;
}

.inner.marketing p{
    text-align: justify;
}

.automation-image {
    padding: 0px 20px 0px 20px;
}
.arttext > img,
.arttext > a > img,
.arttext > p > img,
.arttext > p > a > img 
{
    max-width: 100%;
}
.footer-soc-media,
.footer-soc-media:visited {
    padding-left: 20px;
    color: white;
}
.header-soc-media,
.header-soc-media:visited {
    padding-right: 20px;
    color: white;
}
button:disabled,
button:disabled:hover,
input[type=submit]:disabled,
input[type=submit]:disabled:hover {
    cursor: not-allowed;
    box-shadow: unset;
    background-color: rgba(0,122,229, 0.4);
}

.img-responsive{
    max-width:100%;
    height:auto;
}    

.text-center {
    text-align: center !important;
  }

  .d-flex {
    display:flex;

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;                 
    }
}

.column {
    flex-basis: 50%;
    flex-grow: 0;
    padding-left: 15px;
    padding-right: 15px;
}

.management-consulting {
    .global{
        padding: 50px 0px 0px 0px;

        div {
            padding: 15px;
            width: 240px;
        }
    }

    .second-section {
        padding: 50px 0px 50px 0px;
    }
}

.column-img {
    max-width: 42%;
}

.column-text {
    max-width: 58%;
}

.container{
    &.section{
        margin-top: 130px;
    }
}

.m-20 {
    margin: 20px;
}

.p-30 {
    padding: 50px 0px 50px 70px;
}

.wrapper {
    padding: 1.25em;
}

.privacypolicypage {
    padding-top: 4em;
}

.development {
    display: flex;
    justify-content: space-between;

    div{
        display: block;
        text-align: center;
        width: 240px;
    }

    img {
        max-height: 90px;
        padding-bottom: 5px;
    }
}

.justify-content-between{
    justify-content: space-between;
}

.flex-wrap{
    flex-wrap: wrap;
}

.spaced-list{
    li{
        margin-bottom: 2em;
    }
}

.unstyled-item{
    list-style-type: none;
}

.justify-content-start{
    justify-content: start;
}

@media only screen and (max-width: 1169px) {
    .nomob {
        display: none
    }
    .onlymob {
        display: block
    }


    .case-studies {
        .case-study-item {
            max-width: 1160px;
        }

        .case-studies-container {
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
    }

    body {
        padding-bottom: 360px;
    }
    h1 {
        font-size: 32px;
    }
    h2 {
        font-size: 24px;
    }
    .inner {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    nav .inner, 
    .global.inner, 
    .articlespage .inner, 
    .contactpage .inner, 
    footer .inner, 
    #bignav .inner {
        width: 100%;
    }
    .inner.hero .textblock {
        width: 100%;
        padding: 0 0 40px 0;
    }
    .inner.hero img {
        position: static;
        width: 100%;
        height: 100%;
        margin-top: 30px;
    }
    .shadowbox {
        width: 100%;
        height: auto;
        text-align: left;
        padding: 40px 32px;
        font-size: 16px;
    }
    .global.flex,
    .services .flex,
    .projects .flex,
    .marketing .flex,
    .pagehero .inner.flex,
    .contactpage .inner.flex,
    article .lead .inner.flex,
    footer .inner.flex,
    #bignav .navitems.flex {
        flex-direction: column;
    }
    .global div {
        width: 100%;
    }
    .articles h1, 
    .articles .box, 
    .inner.services {
        width: 100%;
    }

    .technologies {
        height: auto;
        padding: 60px 20px 40px 20px;
    }
    .technologies > div{
        width: 100%;
    }
    .technologies img {
        margin: 0;
        width: 100px;
    } 

    .services > div > div,
    .services div.flex div.flex > div {
        width: 100%;
    }    

    div.jumptop {
        order: -1
    }
    .services img.big {
        width: 100%
    }
    .projects {
        width: 100%;
        padding: 80px 20px 40px 20px;
    }
    .projects .flex > div {
        width: 100% !important;
    }    

    .collection,
    .collection > div {
        width: 100%;
        margin: 0;
    }
    .collection .block {
        width: calc(100vw - 40px);
        height: calc(100vw - 40px);
        margin: 0 auto; 
    }
    .pagehero .inner {
        height: auto;
    }
    .pageheropic {
        width: 100%;
    }
    .pagehero p {
        width: 100%;
    }
    .contactpage .inner.flex.sb > div {
        width: 100% !important;
    }

    .articlehero .inner {
        width: 100%;
        padding: 50px 100px 50px 20px;
    }
    article .lead .inner {
        width: 100%
    }
    article .lead .inner.flex > div {
        width: 100%;
        padding: 20px;
    }
    article .arttext {
        width: 100%;
        padding: 20px;
    }

    .scrollcontainer .box {
        width: 80vw;
        min-height: 450px;
        padding: 30px;
    }
    .scrollcontainer .box h3 {
        
    }
    .scrollcontainer .box:not(:first-child) {
        margin-left: 20px;
    }
    .scrollcontainer .box:first-child {
        margin-left: 20px;
    }
    .scrollcontainer .box:last-child {
        margin-right: 20px;
    }
    .scrollcontainer .box .text {
        width: 100%;
        white-space: normal
    }
    .scrollcontainer .box .img {
        display: none
    }


    #bignav .navitems {
        padding: 40px 0 0 0;
    }
    #bignav .navitems > div {
        width: 100%;
    }
    #bignav .info {
        width: 100%;
        padding-top: 40px;
    }
    footer {
        height: auto;
    }
    footer .inner {
        padding: 30px 20px;
    }
    footer .inner > div:not(:first-child) {
        margin-top: 20px;
    }
    footer .inner > div:last-child {
        width: inherit;
        text-align: left;
    }
    button, input[type=submit] {
        float: none !important;
        margin-left: 0;
        margin-right: 0;
        width: 100% !important;
    }
    button:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .inner.marketing {
        width: 100%;
    }
    .inner.marketing > div > div > div {
        width: 100%;
    }
    .automation-image {
        padding: inherit;
    }
    .footer-soc-media {
        padding-left: 0px;
        padding-right: 15px;
    }

    .map-container{
        flex-direction: column;
    }
}

@media (max-width: 576px) { 
body{
    padding-top:96px;
}

    .icon-section{
        font-size: 12px;
    }

    .map-container{
        flex-direction: column;
    }


    .d-flex {
        flex-direction: column;
    }

    .management-consulting {
        .global{
    
            div {
                width: 100%;
            }
        }
    }

    .p-30 {
        padding: 20px 0px 20px 20px;
    }

    .column-img {
        max-width: 100%;
    }
    
    .column-text {
        max-width: 100%;
    }
 }

@media (max-width: 768px) { 
    .icon-section{
        font-size: 12px;
    }

    .d-flex {
        flex-direction: column;
    }

    .column-img {
        max-width: 100%;
    }
    
    .column-text {
        max-width: 100%;
    }

    .p-30 {
        padding: 20px 0px 20px 20px;
    }

    .footer-container{
        flex-direction: column;
    }

    .management-consulting {
        .global{
    
            div {
                width: 100%;
            }
        }
    }

    .footer-container div {
        padding-left:1em;
        padding-top: 1em;
    }

    .map-container{
        flex-direction: column;
    }
 }

@media (max-width: 992px) { 
    .icon-section{
        font-size: 14px;
    }

    .map-container{
        flex-direction: column;
    }
 }

/// used to be main.scss below ///

// Larsseit Light
@font-face {
font-family: 'Larsseit';
src: url('/assets/fonts/Larsseit-Light.eot');
src: url('/assets/fonts/Larsseit-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Larsseit-Light.woff') format('woff'),
    url('/assets/fonts/Larsseit-Light.ttf') format('truetype');
font-weight: 300;
font-style: normal;
}

// Larsseit Regular
@font-face {
font-family: 'Larsseit';
src: url('/assets/fonts/Larsseit.eot');
src: url('/assets/fonts/Larsseit.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Larsseit.woff') format('woff'),
    url('/assets/fonts/Larsseit.ttf') format('truetype');
font-weight: normal;
font-style: normal;
}

// Larsseit Medium
@font-face {
font-family: 'Larsseit';
src: url('/assets/fonts/Larsseit-Medium.eot');
src: url('/assets/fonts/Larsseit-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Larsseit-Medium.woff') format('woff'),
    url('/assets/fonts/Larsseit-Medium.ttf') format('truetype');
font-weight: 500;
font-style: normal;
}

// Larsseit Bold
@font-face {
font-family: 'Larsseit';
src: url('/assets/fonts/Larsseit-Bold.eot');
src: url('/assets/fonts/Larsseit-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Larsseit-Bold.woff') format('woff'),
    url('/assets/fonts/Larsseit-Bold.ttf') format('truetype');
font-weight: 700;
font-style: normal;
}

::-webkit-scrollbar               { width: 9px; height: 9px; overflow:visible }
::-webkit-scrollbar-button        { height: 0; width: 0}
::-webkit-scrollbar-track         { background-color: rgba(0, 0, 0, .01); border-width: 0; border-radius: 4.5px }
::-webkit-scrollbar-track:hover   { background-color: rgba(0, 0, 0, .01)}
::-webkit-scrollbar-track:active  { background-color: rgba(0, 0, 0, .01)}
::-webkit-scrollbar-thumb         { background-color: rgba(0, 0, 0, .3); background-clip: padding-box; border: solid transparent; border-width: 2px; min-height: 25px; padding: 100px 0 0; border-radius: 4.5px;}
::-webkit-scrollbar-thumb:hover   { background-color: rgba(0, 0, 0, .4) }
::-webkit-scrollbar-thumb:active  { background-color: rgba(0, 0, 0, .5) }
::-webkit-scrollbar-corner        { background: transparent }

* {
    transition: all .35s cubic-bezier(.19, 1, .22, 1);
    -webkit-transition: all .35s cubic-bezier(.19, 1, .22, 1);
}

/* Box model fix for all BEGIN */
html {
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;
    font-size: 62.5%;
}
*, *:before, *:after {
box-sizing: inherit;
}
/* Box model fix for all END */
html, body {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;

    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    padding: 96px 0 150px 0;
    margin: 0;
    min-height: 100%;
    position: relative;
    background-color: white;
}
body, table, input, textarea, button {
    font-family: Larsseit;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #000000;
}
h1 {
    margin-top: 0;
    font-size: 48px;
    font-weight: bold;
    line-height: 1.33;
}

h2 {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.25;
}

h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
}

h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    margin-bottom: 16px;
}

.mid {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
}

h2.leadheadline {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    color: #F2A000;
    border-left: 2px solid #F2A000;
    padding-left: 20px;
    margin: 0 0 30px -20px;
}
article h2 {
    color: #F2A000;
}

.small {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.67;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}
a, a:visited {
    color: #007AE5;
    text-decoration: none;
}
a:hover .hovu {
    text-decoration: underline;
}
a p {
    color: #000000
}
a:hover p {
    text-decoration: none
}
button, input[type=submit] {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    width: 240px;
    height: 48px;
    box-shadow: 0 4px 12px 0 rgba(0, 82, 153, 0.24);
    color: white;
    background-color: #007ae5;
    border-radius: 5px;
    border: 0;
    margin-right: 40px;
    cursor: pointer;
}
button:hover, input[type=submit]:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 82, 153, 0.36);
}
button:active, input[type=submit]:active {
    background-color: #005DAE
}

:focus {
    outline: 0;
}
.flex {
    display: flex
}
.gray {
    color: #7A7A7A
}
.centered {
    text-align: center;
}
.lefted {
    text-align: left;
}
.clickable {
    cursor: pointer
}
.floleft {
    float: left
}
.hidden {
    display: none
}
.semibold {
    font-weight: 500;
}
.sb {
    justify-content: space-between;
}
.cn {
justify-content: center;
}
.bluetext {
    color: #007AE5
}
.blueback {
    background-color: #007AE5
}
.clears {
    clear: both; width: 0 !important; height: 0 !important; line-height: 0 !important; overflow: hidden !important;
}
.clearfix {
    *zoom: 1;
}
.clearfix:after {
    clear: both;
    content: "";
    display: table;
}
.clamp3 {
    line-height: 28px;
    height: 84px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
